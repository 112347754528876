.text-black {
  color: #000;
}
.text-purple {
  color: #6420cf;
}
a {
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  text-decoration: underline;
  transition: all 0.4s;
}
a:hover {
  color: #d9d9d9;
}
.btn {
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  text-decoration: none;
}
.btn:hover,
.btn:focus {
  text-decoration: none;
}
.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: none;
}
.btn:disabled,
.btn.disabled {
  opacity: 0.5;
  box-shadow: none;
  pointer-events: none;
}
.btn:not([disabled]):not(.disabled) {
  cursor: pointer;
}
.btn.btn-purple {
  background: #6420cf;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  color: #fff;
  padding: 24px 48px;
}
* {
  outline: 0 !important;
}
html,
body {
  font-family: 'Inter', sans-serif;
  font-size: 24px;
  color: #000;
}
.container-fluid {
  padding-left: 72px;
  padding-right: 72px;
}
@media (max-width: 768px) {
  .container-fluid {
    padding-left: 24px;
    padding-right: 24px;
  }
}
.row {
  margin-left: -24px;
  margin-right: -24px;
}
@media (max-width: 768px) {
  .row {
    margin-left: -12px;
    margin-right: -12px;
  }
}
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  padding-left: 24px;
  padding-right: 24px;
}
@media (max-width: 768px) {
  .col,
  .col-1,
  .col-10,
  .col-11,
  .col-12,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-auto,
  .col-lg,
  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-auto,
  .col-md,
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-auto,
  .col-sm,
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-auto,
  .col-xl,
  .col-xl-1,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-auto {
    padding-left: 12px;
    padding-right: 12px;
  }
}
footer {
  background: #000;
  padding: 120px 0;
}
footer .contact-big {
  cursor: none;
  font-style: normal;
  font-weight: 600;
  font-size: 72px;
  line-height: 80px;
  color: #fff;
  display: inline-block;
  margin-bottom: 120px;
  text-decoration: none;
}
footer .contact-big:hover {
  position: relative;
  color: #727272;
  text-decoration: none;
}
footer .contact-big:hover span {
  color: #fff;
  text-decoration: underline;
}
footer .cursor {
  position: fixed;
  width: 96px;
  height: 96px;
  border-radius: 100%;
  background-color: #6420cf;
  background-image: url("../img/svg/arrow.svg");
  background-size: 28px;
  background-repeat: no-repeat;
  background-position: center;
  display: none;
  z-index: 0;
}
footer .contact-big:hover ~ .cursor {
  display: block;
}
footer .mail {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  color: #fff;
  text-decoration: none;
}
footer .mail:hover {
  color: #727272;
}
footer .social {
  margin: 0 -24px;
  padding: 0;
  list-style: none;
}
footer .social li {
  margin: 0 24px;
  padding: 0;
  display: inline-block;
  vertical-align: middle;
}
footer .social li a {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  color: #fff;
  text-decoration: none;
}
footer .social li a:hover {
  color: #727272;
}
@media (max-width: 998px) {
  footer {
    padding: 72px 0;
  }
  footer .contact-big {
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 72px;
  }
  footer .social {
    margin: 0 -12px;
  }
  footer .social li {
    margin: 0 12px;
  }
}
@media (max-width: 768px) {
  footer .social {
    margin: 24px 0;
  }
  footer .social li {
    display: block;
    width: 100%;
    margin: 0;
  }
}
#header {
  position: relative;
  background-image: url("../img/svg/header_pattern.svg");
  background-repeat: no-repeat;
}
#header .navbar {
  padding: 60px 0 204px;
}
#header .navbar .logo img {
  width: 120px;
}
#header .navbar .menu {
  margin: 0;
  padding: 0;
  display: block;
  list-style: none;
}
#header .navbar .menu li {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  padding: 0;
}
#header .navbar .menu li a {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  color: #000;
  text-decoration: none;
}
#header .navbar .menu li a:hover {
  color: #727272;
}
#header .headline {
  padding-bottom: 24px;
}
#header .headline h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 72px;
  line-height: 80px;
  color: #000;
}
#header .headline h1.smaller {
  font-size: 64px;
  line-height: 72px;
}
#header .headline p {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #727272;
}
#header .subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  text-transform: uppercase;
  color: #000;
  padding-bottom: 48px;
}
@media (max-width: 998px) {
  #header .headline h1 {
    font-size: 40px;
    line-height: 48px;
  }
  #header .headline h1.smaller {
    font-size: 38px;
    line-height: 44px;
  }
  #header .headline p {
    font-size: 16px;
    line-height: 20px;
  }
}
@media (max-width: 768px) {
  #header .navbar {
    padding: 48px 0 160px;
  }
}
@media (max-width: 576px) {
  #header .btn {
    width: 100%;
  }
}
.portfolio #header .navbar {
  padding-bottom: 108px !important;
}
.portfolio #header .headline {
  padding-bottom: 134px !important;
}
@media (max-width: 768px) {
  .portfolio #header .navbar {
    padding-bottom: 238px !important;
  }
  .portfolio #header .headline {
    padding-bottom: 64px !important;
  }
}
.block-black {
  background: #262626;
  padding: 48px 0;
  height: 100vh;
  position: relative;
}
.block-black .subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 140px;
}
.block-black p {
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 56px;
  color: #fff;
  width: 60%;
  display: block;
  transition: all 1s;
}
.block-black .anim {
  position: absolute;
  bottom: -50px;
  right: -100px;
  transition: all 1s;
}
.block-black .top-left-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: all 1s;
}
.block-black ul.list {
  margin: 0;
  padding: 0;
  list-style: none;
  counter-reset: section;
}
.block-black ul.list li {
  position: relative;
  margin: 0;
  padding: 48px 72px 48px 56px;
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  line-height: 40px;
  color: #fff;
  border-bottom: 1px solid #727272;
}
.block-black ul.list li:last-child {
  border: none;
}
.block-black ul.list li:before {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  counter-increment: section;
  content: counter(section);
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #fff;
}
.block-black.about-company {
  height: auto;
  padding: 0;
}
.block-black.about-company .subtitle {
  margin-top: 48px;
}
.block-black.about-company h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 72px;
  line-height: 80px;
  position: sticky;
  top: calc(100vh - 200px);
  color: #fff;
  transition: all 1s;
  margin-bottom: 120px;
}
.block-black.start p {
  margin-top: 60vh !important;
}
.block-black.start .anim {
  bottom: -100vh !important;
  right: -100vh !important;
}
.block-black.start .top-left-bg {
  top: 50vh;
}
.block-black.start.about-company h3 {
  top: 100vh;
}
.block-black.start.about-company ul li {
  right: -100vw;
}
.block-black.start.about-company ul li:nth-child(2) {
  right: -140vw;
}
.block-black.start.about-company ul li:nth-child(3) {
  right: -180vw;
}
.block-black.start.about-company ul li:nth-child(4) {
  right: -220vw;
}
.block-black.start.about-company ul li:nth-child(5) {
  right: -240vw;
}
.block-black.start.about-company ul li:nth-child(6) {
  right: -280vw;
}
.block-black.start.about-company ul li:nth-child(7) {
  right: -320vw;
}
@media (max-width: 998px) {
  .block-black {
    height: 70vh;
  }
  .block-black .subtitle {
    margin-bottom: 72px;
  }
  .block-black p {
    font-size: 24px;
    line-height: 32px;
    width: 100%;
  }
  .block-black ul.list li {
    font-size: 18px;
    line-height: 28px;
    padding: 24px 0px 24px 48px;
  }
  .block-black ul.list li:before {
    font-size: 18px;
    line-height: 28px;
  }
  .block-black.about-company h3 {
    font-size: 38px;
    line-height: 44px;
  }
}
@media (max-width: 768px) {
  .block-black {
    height: auto;
  }
  .block-black .top-left-bg {
    max-width: 100%;
    width: 100%;
  }
  .block-black .anim {
    position: static;
    width: 100%;
    margin: 48px 0 0;
  }
  .block-black.about-company h3 {
    position: static;
    margin-bottom: 80px !important;
  }
}
.portfolio-block {
  position: relative;
  padding: 160px 0;
}
.portfolio-block .subtitle {
  font-weight: 300;
  font-size: 18px;
  line-height: 20px;
  text-transform: uppercase;
}
.portfolio-block .subtitle span {
  margin-left: 24px;
}
.portfolio-block h3 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 24px;
  color: #fff;
}
.portfolio-block p {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 24px;
  color: #fff;
}
.portfolio-block .nav-list {
  margin-top: 48px;
  position: absolute;
  bottom: 0;
}
.portfolio-block .nav-list a {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  display: block;
  text-decoration: none;
  transition: all 0.4s;
}
.portfolio-block blockquote {
  position: relative;
}
.portfolio-block blockquote img {
  position: absolute;
  top: -4px;
  left: -84px;
}
.portfolio-block .seperator {
  margin: 120px 0;
}
.portfolio-block .credits li {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
}
.portfolio-block.black {
  background: #262626;
}
.portfolio-block.black .subtitle {
  color: #fff;
}
.portfolio-block.black .subtitle span {
  color: #727272;
}
.portfolio-block.black h3,
.portfolio-block.black p {
  color: #fff;
}
.portfolio-block.black .nav-list a {
  color: #f0f0f0;
}
.portfolio-block.black .nav-list a:hover {
  color: #727272;
}
.portfolio-block.black .seperator {
  background-color: #fff;
}
.portfolio-block.black .credits li {
  color: #f0f0f0;
  margin-bottom: 16px;
}
.portfolio-block.black .credits li:last-child {
  margin: 0;
}
.portfolio-block.black .credits li span {
  color: #727272;
  margin-left: 24px;
}
.portfolio-block.white {
  background: #fff;
}
.portfolio-block.white .subtitle {
  color: #000;
}
.portfolio-block.white .subtitle span {
  color: #727272;
}
.portfolio-block.white h3,
.portfolio-block.white p {
  color: #000;
}
.portfolio-block.white .nav-list a {
  color: #262626;
}
.portfolio-block.white .nav-list a:hover {
  color: #000;
}
.portfolio-block.white .seperator {
  background-color: #000;
}
.portfolio-block.white .credits li {
  color: #000;
}
.portfolio-block.white .credits li span {
  color: #262626;
}
@media (max-width: 992px) {
  .portfolio-block {
    padding: 64px 0;
  }
  .portfolio-block .subtitle {
    margin-bottom: 48px;
  }
  .portfolio-block h3 {
    font-size: 22px;
    line-height: 28px;
  }
  .portfolio-block .nav-list a,
  .portfolio-block p {
    font-size: 16px;
    line-height: 24px;
  }
  .portfolio-block blockquote {
    padding-left: 60px;
  }
  .portfolio-block blockquote img {
    left: 0px;
  }
  .portfolio-block .seperator {
    margin: 64px 0;
  }
  .portfolio-block .credits li span {
    margin: 0 !important;
    display: block;
  }
}
.block-white {
  padding: 48px 0;
  position: relative;
}
.block-white .subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  text-transform: uppercase;
  color: #6420cf;
  margin-bottom: 140px;
}
.block-white p {
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 56px;
  color: #000;
  width: 75%;
  display: block;
  transition: all 1s;
}
.block-white ul {
  margin: 120px 0 0;
  padding: 0;
  list-style: none;
  counter-reset: section;
  overflow: hidden;
}
.block-white ul li {
  position: relative;
  right: 0;
  margin: 0;
  padding: 72px 0 72px 72px;
  font-style: normal;
  font-weight: 300;
  font-size: 56px;
  line-height: 72px;
  color: #000;
  border-bottom: 1px solid #d9d9d9;
  transition: all 2s;
}
.block-white ul li:first-child {
  border-top: 1px solid #d9d9d9;
}
.block-white ul li:before {
  counter-increment: section;
  content: counter(section);
  position: absolute;
  left: 0;
  top: 72px;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #6420cf;
}
.block-white#open-position {
  padding: 48px 0 120px;
}
.block-white#open-position ul li {
  padding: 48px 0;
}
.block-white#open-position ul li a {
  text-decoration: none;
}
.block-white#open-position ul li a:hover {
  color: inherit;
}
.block-white#open-position ul li h3 {
  font-style: normal;
  font-weight: 300;
  font-size: 56px;
  line-height: 72px;
}
.block-white#open-position ul li p {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #727272;
  white-space: nowrap;
}
.block-white#open-position ul li .btn-apply {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #000;
  transition: all 0.4s;
}
.block-white#open-position ul li .btn-apply:after {
  content: '';
  display: inline-block;
  vertical-align: middle;
  margin-left: 24px;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  border: 2px solid #6420cf;
  background-image: url("../img/svg/arrow_purple.svg");
  background-size: 28px;
  background-repeat: no-repeat;
  background-position: center;
  transition: all 0.4s;
}
.block-white#open-position ul li:hover .btn-apply {
  color: #6420cf;
  text-decoration: none;
}
.block-white#open-position ul li:hover .btn-apply:after {
  background-color: #6420cf;
  background-image: url("../img/svg/arrow.svg");
}
.block-white#open-position ul li:before {
  content: none;
}
.block-white.start p.title {
  margin-top: 60vh !important;
}
.block-white.start ul li {
  right: -100vw;
}
.block-white.start ul li:nth-child(2) {
  right: -140vw;
}
.block-white.start ul li:nth-child(3) {
  right: -180vw;
}
.block-white.start ul li:nth-child(4) {
  right: -220vw;
}
.block-white.start ul li:nth-child(5) {
  right: -240vw;
}
.block-white.start ul li:nth-child(6) {
  right: -280vw;
}
.block-white.start ul li:nth-child(7) {
  right: -320vw;
}
@media (max-width: 998px) {
  .block-white .subtitle {
    margin-bottom: 120px;
  }
  .block-white p {
    font-size: 32px;
    line-height: 36px;
    width: 100%;
  }
  .block-white ul {
    margin: 100px 0;
  }
  .block-white ul li {
    font-size: 32px;
    line-height: 36px;
  }
  .block-white#open-position ul li {
    padding: 48px 0;
  }
  .block-white#open-position ul li h3 {
    font-size: 32px;
    line-height: 36px;
  }
  .block-white#open-position ul li p {
    font-size: 16px;
    line-height: 24px;
    white-space: normal;
  }
  .block-white#open-position ul li .btn-apply {
    font-size: 16px;
    line-height: 24px;
  }
}
@media (max-width: 768px) {
  .block-white#open-position ul li a:after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    margin-left: 24px;
    width: 32px;
    height: 32px;
    background-image: url("../img/svg/arrow_purple.svg");
    background-size: 32px;
    background-repeat: no-repeat;
    background-position: center;
  }
  .block-white#open-position ul li a .btn-apply {
    display: none;
  }
}
#highligh-project {
  padding: 0;
  transition: all 1s;
}
#highligh-project #carousel {
  position: relative;
  width: 100%;
  height: 100vh;
}
#highligh-project #carousel .highligh {
  position: absolute;
  top: 48px;
  left: 72px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  text-transform: uppercase;
  color: #fff;
  z-index: 1;
}
#highligh-project #carousel .carousel-inner {
  height: 100vh;
}
#highligh-project #carousel .carousel-inner .carousel-item {
  height: 100vh;
  position: relative;
  overflow: hidden;
  background-position: center;
  background-size: cover;
}
#highligh-project #carousel .carousel-inner .carousel-item img {
  display: none !important;
}
#highligh-project #carousel .carousel-control-prev,
#highligh-project #carousel .carousel-control-next {
  opacity: 0;
}
#highligh-project #carousel .carousel-control-prev:hover,
#highligh-project #carousel .carousel-control-next:hover {
  opacity: 0.5;
}
#highligh-project #carousel .carousel-control-prev-icon,
#highligh-project #carousel .carousel-control-next-icon {
  width: 96px;
  height: 96px;
  border-radius: 100%;
  background-color: #6420cf;
  background-image: url("../img/svg/arrow.svg");
  background-size: 28px;
  background-repeat: no-repeat;
  background-position: center;
}
#highligh-project #carousel .carousel-control-prev {
  transform: rotate(180deg);
}
#highligh-project #carousel .carousel-control-prev-mobil,
#highligh-project #carousel .carousel-control-next-mobil {
  display: none;
}
#highligh-project #carousel .carousel-indicators {
  position: absolute;
  bottom: 72px;
  right: 72px;
  left: unset;
  display: flex;
  margin: 0;
}
#highligh-project #carousel .carousel-indicators li {
  padding: 0px 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #fff;
  background: none;
  text-indent: 0;
  width: auto;
  height: auto;
  margin: 0;
  opacity: 1;
  transition: all 0.4s;
}
#highligh-project #carousel .carousel-indicators li.active {
  border-bottom: 1px solid #fff;
}
#highligh-project #carousel .carousel-caption {
  position: absolute;
  left: 72px;
  bottom: 72px;
  text-align: left;
  padding: 0;
  margin: 0;
  right: unset;
  z-index: 0;
}
#highligh-project #carousel .carousel-caption h6 {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 24px;
}
#highligh-project #carousel .carousel-caption h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 72px;
  line-height: 80px;
  color: #fff;
  margin-bottom: 40px;
  width: 60%;
}
#highligh-project #carousel .carousel-caption ul {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 80%;
}
#highligh-project #carousel .carousel-caption ul li {
  display: inline-block;
  vertical-align: middle;
  margin: 8px;
  background: rgba(255,255,255,0.16);
  border-radius: 32px;
  padding: 8px 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
}
#highligh-project #carousel .carousel-caption ul li:first-child {
  margin-left: 0;
}
#highligh-project #carousel .carousel-caption ul li:last-child {
  margin-right: 0;
}
#highligh-project.start {
  padding: 0 72px;
}
@media (max-width: 1200px) {
  #highligh-project #carousel .carousel-caption h4 {
    font-size: 48px;
    line-height: 52px;
    margin-bottom: 20px;
  }
}
@media (max-width: 992px) {
  #highligh-project #carousel .carousel-control-prev,
  #highligh-project #carousel .carousel-control-next {
    display: none;
  }
  #highligh-project #carousel .carousel-control-prev-mobil,
  #highligh-project #carousel .carousel-control-next-mobil {
    position: absolute;
    display: block;
    top: unset;
    width: 32px;
    height: 32px;
    bottom: 24px;
    left: unset;
  }
  #highligh-project #carousel .carousel-control-prev-mobil {
    transform: rotate(180deg);
    right: 126px;
  }
  #highligh-project #carousel .carousel-control-next-mobil {
    right: 72px;
  }
  #highligh-project #carousel .carousel-control-prev-mobil .carousel-control-prev-icon,
  #highligh-project #carousel .carousel-control-next-mobil .carousel-control-next-icon {
    background-color: transparent;
    width: 32px;
    height: 32px;
    border: none;
    background-size: 32px;
  }
  #highligh-project #carousel .carousel-indicators {
    left: 72px;
    right: unset;
    bottom: 24px;
  }
  #highligh-project #carousel .carousel-caption h4 {
    font-size: 36px;
    line-height: 40px;
    width: 100%;
    margin-bottom: 40px;
  }
  #highligh-project #carousel .carousel-caption ul {
    display: none;
  }
}
@media (max-width: 768px) {
  #highligh-project {
    padding: 0 !important;
  }
  #highligh-project .btn-purple {
    width: 100%;
  }
  #highligh-project #carousel .carousel-control-prev-mobil {
    right: 76px;
  }
  #highligh-project #carousel .carousel-control-next-mobil {
    right: 20px;
  }
  #highligh-project #carousel .carousel-indicators,
  #highligh-project #carousel .highligh,
  #highligh-project #carousel .carousel-caption {
    left: 20px;
  }
}
#header.black-half {
  background: none;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
}
#header.black-half .menu a {
  color: #fff !important;
}
#header.black-half .menu a:hover {
  color: #d9d9d9 !important;
}
@media (max-width: 768px) {
  #header.black-half .menu a {
    color: #000 !important;
  }
  #header.black-half .menu a:hover {
    color: #727272 !important;
  }
}
.job {
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 0;
}
.job .left-side,
.job .right-side {
  position: relative;
  width: 50%;
}
.job .left-side {
  position: relative;
  background-image: url("../img/svg/header_pattern.svg");
  background-attachment: fixed;
}
.job .left-side a {
  position: sticky;
  top: calc(100vh - 81px);
  left: 0;
}
.job .left-side h1 {
  position: sticky;
  top: 194px;
  display: block;
  font-style: normal;
  font-weight: 600;
  font-size: 72px;
  line-height: 80px;
  color: #000;
  margin-bottom: calc(100vh - (194px + 72px + 82px));
  margin-left: 72px;
}
.job .right-side {
  background: #262626;
  color: #fff;
  padding: 120px 72px 0 42px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
}
.job .right-side h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin: 64px 0 32px;
}
.job .right-side p {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 32px;
}
.job .right-side ul {
  list-style: disc;
  padding-left: 25px;
}
.job .right-side ul li {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 16px;
}
.job .right-side ul li:last-child {
  margin: 0;
}
.job .right-side .btn {
  margin-left: -42px;
}
@media (max-width: 998px) {
  .job .left-side h1 {
    font-size: 40px;
    line-height: 48px;
  }
  .job .right-side {
    font-size: 18px;
    line-height: 28px;
  }
  .job .right-side h3 {
    font-size: 24px;
    line-height: 32px;
  }
  .job .right-side p {
    font-size: 18px;
    line-height: 28px;
  }
  .job .right-side ul {
    padding-left: 25px;
  }
  .job .right-side ul li {
    font-size: 18px;
    line-height: 28px;
  }
}
@media (max-width: 768px) {
  .job {
    display: block;
  }
  .job .left-side,
  .job .right-side {
    width: 100%;
  }
  .job .left-side {
    height: 100vh;
  }
  .job .left-side h1 {
    margin-left: 24px;
    position: absolute;
    top: unset;
    bottom: 124px;
    margin-bottom: 0;
  }
  .job .left-side a {
    width: 100%;
  }
  .job .right-side {
    padding: 64px 20px 20px;
  }
  .job .right-side .btn {
    display: none;
  }
}
.partners {
  margin-top: 200px;
  padding: 120px 0 112px;
  background: #f0f0f0;
}
.partners h2 {
  margin-bottom: 8px;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 40px;
  text-transform: uppercase;
  color: #000;
}
.partners .logos > div {
  margin: 36px 0;
  transition: all 2s;
}
.partners.start .logos > div {
  opacity: 0;
}
@media (max-width: 998px) {
  .partners {
    margin: 0;
  }
}
