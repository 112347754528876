.block-white{
    padding: 48px 0;
    position: relative;
    .subtitle{
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        text-transform: uppercase;
        color: $purple;
        margin-bottom: 140px;
    }

    p{
        font-style: normal;
        font-weight: 600;
        font-size: 48px;
        line-height: 56px;
        color: $black;
        width: 75%;
        display: block;
        transition: all 1s;
    }

    ul{
        margin: 120px 0 0;
        padding: 0;
        list-style: none;
        counter-reset: section;
        overflow: hidden;
        li{
            position: relative;
            right: 0;
            margin: 0;
            padding: 72px 0 72px 72px;
            font-style: normal;
            font-weight: 300;
            font-size: 56px;
            line-height: 72px;
            color: $black;
            border-bottom: 1px solid $grey;
            transition: all 2s;

            &:first-child{
                border-top: 1px solid $grey;
            }
            &:before{
                counter-increment: section;
                content: counter(section);
                position: absolute;
                left: 0;
                top: 72px;
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 32px;
                color: $purple;
            }
        }
    }

    &#open-position{
        padding: 48px 0 120px;
        ul{
            li{
                a{
                    text-decoration: none;
                    &:hover{
                        color: inherit;
                    }
                }
                padding: 48px 0;
                h3{
                    font-style: normal;
                    font-weight: 300;
                    font-size: 56px;
                    line-height: 72px;
                }

                p{
                    font-style: normal;
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 32px;
                    color: $grey_dark;
                    white-space: nowrap;
                }

                .btn-apply{
                    font-style: normal;
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 32px;
                    color: $black;
                    transition: all .4s;
                    &:after{
                        content: '';
                        display: inline-block;
                        vertical-align: middle;
                        margin-left: 24px;
                        width: 80px;
                        height: 80px;
                        border-radius: 100%;
                        border: 2px solid $purple;
                        background-image: url('../img/svg/arrow_purple.svg');
                        background-size: 28px;
                        background-repeat: no-repeat;
                        background-position: center;
                        transition: all .4s;
                    }
                }
                &:hover{
                    .btn-apply{
                        color: $purple;
                        text-decoration: none;
                        &:after{
                            background-color: $purple;
                            background-image: url('../img/svg/arrow.svg');
                        }
                    }
                }
                &:before{
                    content: none;
                }
            }
        }
    }

    &.start{
        p.title{
            margin-top: 60vh !important;
        }

        ul{
            li{
                right: -100vw;
                &:nth-child(2){right: -140vw;}
                &:nth-child(3){right: -180vw;}
                &:nth-child(4){right: -220vw;}
                &:nth-child(5){right: -240vw;}
                &:nth-child(6){right: -280vw;}
                &:nth-child(7){right: -320vw;}
            }
        }
    }

    @media (max-width: 998px) {
        .subtitle{
            margin-bottom: 120px;
        }

        p{
            font-size: 32px;
            line-height: 36px;
            width: 100%;
        }

        ul{
            margin: 100px 0;
            li{
                font-size: 32px;
                line-height: 36px;
            }
        }

        &#open-position{
            ul{
                li{
                    padding: 48px 0;
                    h3{
                        font-size: 32px;
                        line-height: 36px;
                    }

                    p{
                        font-size: 16px;
                        line-height: 24px;
                        white-space: normal;
                    }

                    .btn-apply{
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
            }
        }
        
    }

    @media (max-width: 768px) {
        &#open-position{
            ul{
                li{
                   a{

                        &:after{
                            content: '';
                            display: inline-block;
                            vertical-align: middle;
                            margin-left: 24px;
                            width: 32px;
                            height: 32px;
                            background-image: url('../img/svg/arrow_purple.svg');
                            background-size: 32px;
                            background-repeat: no-repeat;
                            background-position: center;
                        }
                        .btn-apply{
                            display: none;
                        }
                   }
                }
            }
        }
    }
}