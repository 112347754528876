a{
	font-size: inherit;
	font-weight: inherit;
	line-height: inherit;
	color: inherit;
	text-decoration: underline;
	transition: all .4s;
	&:hover{
		color: $grey;
	}
}

.btn {
	display inline-block
	text-align center
	white-space nowrap
	vertical-align middle
	user-select none
	border 1px solid transparent
	padding .375rem .75rem
	font-size 1rem
	line-height 1.5
	border-radius 0
	transition color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
	text-decoration: none;

	&:hover, &:focus {
		text-decoration none
	}

	&:focus, &.focus {
		outline 0
		box-shadow none
	}

	&:disabled, &.disabled {
		opacity .5
		box-shadow none
		pointer-events none
	}

	&:not([disabled]):not(.disabled) {
		cursor pointer
	}

	&.btn-purple{
		background: $purple;
		font-style: normal;
		font-weight: 600;
		font-size: 18px;
		line-height: 32px;
		color: $white;
		padding: 24px 48px;
	}
}