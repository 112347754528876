.block-black{
    background: $dark_grey;
    padding: 48px 0;
    height: 100vh;
    position: relative;
    .subtitle{
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        text-transform: uppercase;
        color: $white;
        margin-bottom: 140px;
    }

    p{
        font-style: normal;
        font-weight: 600;
        font-size: 48px;
        line-height: 56px;
        color: $white;
        width: 60%;
        display: block;
        transition: all 1s;
    }

    .anim{
        position: absolute;
        bottom: -50px;
        right: -100px;
        transition: all 1s;
    }

    .top-left-bg{
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        transition: all 1s;
    }

    ul.list{
        margin: 0;
        padding: 0;
        list-style: none;
        counter-reset: section;
        li{
            position: relative;
            margin: 0;
            padding: 48px 72px 48px 56px;
            font-style: normal;
            font-weight: 300;
            font-size: 32px;
            line-height: 40px;
            color: $white;
            border-bottom: 1px solid $grey_dark;

            &:last-child{
                border: none;
            }

            &:before{
                position: absolute;
                left: 0;
                top: 50%;
                transform: translate(0, -50%);
                counter-increment: section;
                content: counter(section);
                font-weight: 400;
                font-size: 24px;
                line-height: 32px;
                color: $white;
            }
        }
    }

    &.about-company{
        height: auto;
        padding: 0; 

        .subtitle{
            margin-top: 48px;
        }
        h3{
            font-style: normal;
            font-weight: 600;
            font-size: 72px;
            line-height: 80px;
            position: sticky;
            top: calc(100vh - 200px);
            color: $white;
            transition: all 1s;
            margin-bottom: 120px;
        }

    }

    &.start{
        p{
            margin-top: 60vh !important;
        }
        .anim{
            bottom: -100vh !important;
            right: -100vh !important;
        }

        .top-left-bg{
            top: 50vh;
        }

        &.about-company{
            h3{
                 top: 100vh;
            }
            ul{
                li{
                    right: -100vw;
                    &:nth-child(2){right: -140vw;}
                    &:nth-child(3){right: -180vw;}
                    &:nth-child(4){right: -220vw;}
                    &:nth-child(5){right: -240vw;}
                    &:nth-child(6){right: -280vw;}
                    &:nth-child(7){right: -320vw;}
                }
            }
        }
    }

    @media (max-width: 998px) {
        height: 70vh;
        .subtitle{
            margin-bottom: 72px;
        }

        p{
            font-size: 24px;
            line-height: 32px;
            width: 100%;
        }

        ul.list{
            li{
                font-size: 18px;
                line-height: 28px;
                padding: 24px 0px 24px 48px;
                 &:before{
                    font-size: 18px;
                    line-height: 28px;
                }
            }
        }

        &.about-company{
            h3{
                font-size: 38px;
                line-height: 44px;
            }
        }
    }

    @media (max-width: 768px) {
        height: auto;
        .top-left-bg{
            max-width: 100%;
            width: 100%;
        }

        .anim{
            position: static;
            width: 100%;
            margin: 48px 0 0;
        }

        &.about-company{
            h3{
                position: static;
                margin-bottom: 80px !important;
            }
        }
    }
}