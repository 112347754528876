.partners{
    margin-top: 200px;
    padding: 120px 0 112px;
    background: $grey_light;
    h2{
        margin-bottom: 8px;
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 40px;
        text-transform: uppercase;
        color: $black;
    }

    .logos > div{
        margin: 36px 0;
        transition: all 2s;
    }

    &.start{
        .logos > div{
            opacity: 0;
        }
    }

    @media (max-width: 998px) {
        margin: 0;
    }
}