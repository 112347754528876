footer {
    background: $black;
    padding: 120px 0;
    .contact-big{
        cursor: none;
        font-style: normal;
        font-weight: 600;
        font-size: 72px;
        line-height: 80px;
        color: $white;
        display: inline-block;
        margin-bottom: 120px;
        text-decoration: none;
        &:hover{
            position: relative;
            color: $grey_dark;
            text-decoration: none;
            span{
                color: $white;
                text-decoration: underline;
            }
        }
    } 

    .cursor{
        position: fixed;
        width: 96px;
        height: 96px;
        border-radius: 100%;
        background-color: $purple;
        background-image: url('../img/svg/arrow.svg');
        background-size: 28px;
        background-repeat: no-repeat;
        background-position: center;
        // transform: translate(-50%, -50%);
        display: none;
        z-index: 0;
    }

    .contact-big:hover ~ .cursor{
        display: block;
    }

    .mail{
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 32px;
        color: $white;
        text-decoration: none;
        &:hover{
            color: $grey_dark;
        }
    }

    .social{
        margin: 0 -24px;
        padding: 0;
        list-style: none;
        li{
            margin: 0 24px;
            padding: 0;
            display: inline-block;
            vertical-align: middle;
            a{
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 32px;
                color: $white;
                text-decoration: none;
                &:hover{
                    color: $grey_dark;
                }
            }
        }    
    }

    @media (max-width: 998px) {
        padding: 72px 0;
        .contact-big{
            font-size: 40px;
            line-height: 48px;
            margin-bottom: 72px;
        } 

        .social{
            margin: 0 -12px;
           li{
                margin: 0 12px; 
            }    
        }
    }

    @media (max-width: 768px) { 
        .social{
            margin: 24px 0;
           li{
                display: block;
                width: 100%;
                margin: 0;
            }    
        }
    }
}
