#header{
	position: relative;
	background-image: url('../img/svg/header_pattern.svg');	
	background-repeat: no-repeat;

	.navbar{
		padding: 60px 0 204px;
		.logo img{
			width: 120px;
		}

		.menu{
			margin: 0;
			padding: 0;
			display: block;
			list-style: none;
			li{
				display: inline-block;
				vertical-align: middle;
				margin: 0;
				padding: 0;
				a{
					font-style: normal;
					font-weight: 600;
					font-size: 18px;
					line-height: 32px;
					color: $black;
					text-decoration: none;
					&:hover{
						color: $grey_dark;
					}
				}
			}
		}
	}

	.headline{
		padding-bottom: 24px;
		
		h1{
			font-style: normal;
			font-weight: 600;
			font-size: 72px;
			line-height: 80px;
			color: $black;

			&.smaller{
				font-size: 64px;
				line-height: 72px;
			}
		}

		p{
			font-style: normal;
			font-weight: 400;
			font-size: 24px;
			line-height: 32px;
			color: $grey_dark;
		}
	}

	.subtitle{
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 20px;
		text-transform: uppercase;
		color: $black;
		padding-bottom: 48px;
	}

	@media (max-width: 998px) {
       .headline{
            h1 {
                font-size: 40px;
                line-height: 48px;
				&.smaller{
					font-size: 38px;
					line-height: 44px;
				}
            }

			p{
				font-size: 16px;
				line-height: 20px;
			}
        }
	}

	@media (max-width: 768px) {
		.navbar{
			padding: 48px 0 160px;
		}
	}

	@media (max-width: 576px) {
		.btn{
			width: 100%;
		}
		
	}
}

.portfolio{
	#header{
		.navbar{
			padding-bottom: 108px !important;
		}

		.headline{
			padding-bottom: 134px !important;
		}
	}

	@media (max-width: 768px) {
		#header{
			.navbar{
				padding-bottom: 238px !important;
			}

			.headline{
				padding-bottom: 64px !important;
			}
		}
	}
}