#header.black-half{
    background: none;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    .menu{
        a{
            color: $white !important;
            &:hover{
                color: $grey !important;
            }
        }
    }

    @media (max-width: 768px) {
        .menu{
            a{
                color: $black !important;
                &:hover{
                    color: $grey_dark !important;
                }
            }
        }
    }
}

.job{
    width: 100%;
    display: flex;
    justify-content: space-between;
    z-index: 0;
    .left-side, .right-side{
        position: relative;
        width: 50%;
    }

    .left-side{
        position: relative;
        background-image: url('../img/svg/header_pattern.svg');	
        background-attachment: fixed;
        
        a{
            position: sticky;
            top: calc(100vh - 81px);
            left: 0;
        }

        h1 {
            position: sticky;
            top: 194px;
            display: block;
			font-style: normal;
            font-weight: 600;
			font-size: 72px;
			line-height: 80px;
			color: $black;
            margin-bottom: calc(100vh -(194px + 72px + 82px)); 
            margin-left: 72px;
		}
    }

    .right-side{
        background: $dark_grey;
        color: $white;
        padding: 120px 72px 0 42px;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;

        h3{
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            margin: 64px 0 32px;
        }
        p{
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 32px;
            margin-bottom: 32px;
        }

        ul{
            list-style: disc;
            padding-left: 25px;
            li{
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 28px;
                margin-bottom: 16px;
                &:last-child{
                    margin: 0;
                }
            }
        }

        .btn{
            margin-left: -42px;
        }
    }

    @media (max-width: 998px) {
        .left-side{
            h1{
                font-size: 40px;
                line-height: 48px;
            }
        }

        .right-side{
             font-size: 18px;
             line-height: 28px;
            h3{
                font-size: 24px;
                line-height: 32px;
            }
            p{
                font-size: 18px;
                line-height: 28px;
            }

            ul{
                padding-left: 25px;
                li{
                    font-size: 18px;
                    line-height: 28px;
                }
            }

        }
    }

    @media (max-width: 768px) {
        display: block;
        .left-side, .right-side{
            width: 100%;
        }
        .left-side{
            height: 100vh;

            h1{
                margin-left: 24px;
                position: absolute;
                top: unset;
                bottom: 124px;
                margin-bottom: 0;
            }
            a{
                width: 100%;
            }
        }

        .right-side{
            padding: 64px 20px 20px;
            .btn{
                display: none;
            }
        }
    }
}