.portfolio-block{
    position: relative;
    padding: 160px 0;
    .subtitle{
        font-weight: 300;
        font-size: 18px;
        line-height: 20px;
        text-transform: uppercase;
        span{
            margin-left: 24px;
        }
    }

    h3{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 24px;
        color: white;
    }

    p{
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 24px;
        color: $white;
    }

    .nav-list{
        margin-top: 48px;
        position: absolute;
        bottom: 0;
        a{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 28px;
            display: block;
            text-decoration: none;
            transition: all .4s;
        }
    }

    blockquote{
        position: relative;
        img{
            position: absolute;
            top: -4px;
            left: -84px;
        }
    }

    .seperator{
        margin: 120px 0;
    }

    .credits{
        li{
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 28px;
        }
    }

    &.black{
        background: $dark_grey;
        .subtitle{
            color: $white;
            span{
                color: $grey_dark;
            }
        }

        h3, p{
            color:$white;
        }
        
        .nav-list a{
            color: $grey_light;
            &:hover{
                color: $grey_dark;
            }
        }

        .seperator{
            background-color: $white;
        }

        .credits li{
            color: $grey_light;
            margin-bottom: 16px;

            &:last-child{
                margin: 0;
            }
            span{
                color: $grey_dark;
                margin-left: 24px;
            }
        }
    }
    &.white{
        background: $white;
        .subtitle{
            color: $black;
            span{
                color: $grey_dark;
            }
        }
        h3, p{
            color: black;
        }
        .nav-list a{
            color: $dark_grey;
            &:hover{
                color: $black;
            }
        }
        .seperator{
            background-color: $black;
        }

        .credits li{
            color: $black;
            span{
                color: $dark_grey;
            }
        }
    }

    @media (max-width: 992px) {
        padding: 64px 0;
        .subtitle{
            margin-bottom: 48px;
        }

        h3{
            font-size: 22px;
            line-height: 28px;
        }

        .nav-list a, p{
            font-size: 16px;
            line-height: 24px;
        }

        blockquote{
            padding-left: 60px;
            img{
                left: 0px;
            }
        }

        .seperator{
            margin: 64px 0;
        }

        .credits{
            li{
                span{
                    margin: 0 !important;
                    display: block;
                }
            }
        }
    }
}