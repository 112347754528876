#highligh-project{
    padding: 0;
    transition: all 1s;
    #carousel{
        position: relative;
        width: 100%;
        height: 100vh;
        .highligh{
            position: absolute;
            top: 48px;
            left: 72px;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 20px;
            text-transform: uppercase;
            color: $white;
            z-index: 1;
        }    

        .carousel-inner{
            height: 100vh;
            .carousel-item{
                height: 100vh;
                position: relative;
                overflow: hidden;
                background-position: center;
                background-size: cover;
                img{
                    display: none !important;
                }
            }
        }

        .carousel-control-prev, .carousel-control-next{
            opacity: 0;
            &:hover{
                opacity: .5;
            }
        }
        .carousel-control-prev-icon, .carousel-control-next-icon{
            width: 96px;
            height: 96px;
            border-radius: 100%;
            background-color: $purple;
            background-image: url('../img/svg/arrow.svg');
            background-size: 28px;
            background-repeat: no-repeat;
            background-position: center;

        }

        .carousel-control-prev{
            transform: rotate(180deg);
        }

        .carousel-control-prev-mobil, .carousel-control-next-mobil{
            display: none;
        }

        .carousel-indicators{
            position: absolute;
            bottom: 72px;
            right: 72px;
            left: unset;
            display: flex;
            margin: 0;
            li{
                padding: 0px 16px;
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 28px;
                text-align: center;
                color: $white;
                background: none;
                text-indent: 0;
                width: auto;
                height: auto;
                margin: 0;
                opacity: 1;
                transition: all .4s;
                &.active{
                    border-bottom: 1px solid $white;
                }
            }
        }

        .carousel-caption{
            position: absolute;
            left: 72px;
            bottom: 72px;
            text-align: left;
            padding: 0;
            margin: 0;
            right: unset;
            z-index 0;
            h6{
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 20px;
                text-transform: uppercase;
                color: $white;
                margin-bottom: 24px;
            }

            h4{
                font-style: normal;
                font-weight: 600;
                font-size: 72px;
                line-height: 80px;
                color: $white;
                margin-bottom: 40px;
                width: 60%;
            }

            ul{
                margin: 0;
                padding: 0;
                list-style: none;
                width: 80%;
                li{
                    display: inline-block;
                    vertical-align: middle;
                    margin: 8px;
                    background: rgba(255, 255, 255, 0.16);
                    border-radius: 32px;
                    padding: 8px 16px;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: $white;
                    &:first-child{
                        margin-left: 0;
                    }
                    &:last-child{
                        margin-right: 0;
                    }
                }
            }
        }
    }

    &.start{
        padding: 0 72px;
    }

    @media (max-width: 1200px) {
        #carousel{
            .carousel-caption{
                h4{
                    font-size: 48px;
                    line-height: 52px;
                    margin-bottom: 20px;
                }
            }
        }
    }

    @media (max-width: 992px) {
        #carousel{
            .carousel-control-prev, .carousel-control-next{
                display: none;
            }
            .carousel-control-prev-mobil, .carousel-control-next-mobil{
                position: absolute;
                display: block;
                top: unset;
                width: 32px;
                height: 32px;
                bottom: 24px;
                left: unset;
            }
            
            .carousel-control-prev-mobil{
                transform: rotate(180deg);
                right: 126px;
            } 
            .carousel-control-next-mobil{
                right: 72px;
            }
            .carousel-control-prev-mobil .carousel-control-prev-icon, .carousel-control-next-mobil .carousel-control-next-icon{
                background-color: transparent;
                width: 32px;
                height: 32px;
                border: none;
                background-size: 32px;
            }
            .carousel-indicators{
                left: 72px;
                right: unset;
                bottom: 24px;
            }
            .carousel-caption{
                h4{
                    font-size: 36px;
                    line-height: 40px;
                    width: 100%;
                    margin-bottom: 40px;
                }

                ul{
                    display: none;
                }
            }
        }
    }

    @media (max-width: 768px) {
        padding: 0 !important;
        .btn-purple{
			width: 100%;
		}
        #carousel{
            .carousel-control-prev-mobil{
                right: 76px;
            } 
            .carousel-control-next-mobil{
                right: 20px;
            }
            .carousel-indicators, .highligh, .carousel-caption{
                left: 20px;
            }
        }
    }
}